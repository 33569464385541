import Callout from '../callout'
import Page, { Header } from '../page'
import ServicesList from '../services-list'

const PageServices = () => (
  <>
    <Page container>
      <Header
        title="Services"
        subtitle="We build cutting-edge web and mobile apps for clients around the world. Our
        team of experts delivers everything from ideation to design and
        engineering, guiding our clients along the way."
      />
      <ServicesList />
    </Page>
    <Callout />
  </>
)

export default PageServices
