import PageServices from '../../components/page-services'
import Seo from '../../components/seo'

const Services = () => (
  <>
    <Seo title="Services" />
    <PageServices />
  </>
)

export default Services
